(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1245;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu-wrapper .header-menu > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');


  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.outerHeight();
  });

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = function() {
    return WINDOW_WIDTH > DESKTOP_WIDE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  // ---------
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: true
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  $WINDOW
    .on('load', function() {
      $DOCUMENT.trigger('headerMenuEvents')
    })
    .trigger('resize');


  $BODY
    .on('yiiListViewUpdating', function(id) {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', function(id, data) {
      $DOCUMENT
        .trigger('initSpinner')
        .trigger('initSelectric');
      $.mouseLoader(false);
    });


  $DOCUMENT
    .ready(function() {
      $DOCUMENT
        .trigger('setHeaderFixed')
        .trigger('initSpinner')
        .trigger('initSelectric')
        .trigger('filtersBlockEvents');

      $WINDOW
        .on('resize', function() {
          $DOCUMENT
            .trigger('setHeaderFixed')
            .trigger('headerMenuEvents')
            .trigger('filtersBlockEvents');
        });


      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Main rotator
      $('#main-rotator').slick($.extend({}, slickSettings, {
        arrows: true,
        dots: true,
        centerMode: true,
        centerPadding: '0px',
        slide: '.main-slide-container',
        appendArrows: '#main-rotator-arrows',
        appendDots: '#main-rotator-dots',
        draggable: true
      }));

      // Product gallery
      // ---------------
      $('.product-card .product-gallery').slick($.extend({}, slickSettings, {
        autoplay: false,
        fade: true,
        arrows: false,
        dots: true,
        appendDots: '.product-gallery-container > .product-gallery-pager',
        customPaging: function(slick, index) {
          return $('<div />', {
            style: 'background-image:url("'.concat(slick.$slides.eq(index).children().data('imagePre'), '")')
          });
        },
        draggable: true
      }));
    })

    // Set header fixed
    // ----------------
    .on('setHeaderFixed', function() {
      if( IS_DESKTOP_WIDTH() ) {
        $HEADER.removeClass('fixed');
        $BODY.removeClass('search-is-opened').css('padding-top', 0);
      }
      else {
        $HEADER.addClass('fixed');
        $BODY.css('padding-top', HEADER_HEIGHT);
      }
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if (IS_DESKTOP_WIDTH()) {
        if (!$HEADER.hasClass('is-desktop-events')) {
          $HEADER
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events');

          $DOCUMENT.off('click.closeHeaderMenu click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .on('setHeightCatalogSubmenu', function(e, $submenu) {
              $HEADER_MENU.find('> .catalog-item > ul').css('min-height', function() {
                var $menu = $(this);

                if (!$submenu)
                  $submenu = $menu.find('> li:first-child > ul');

                return ($submenu.length && ($submenu.outerHeight() >= $menu.outerHeight()) ? $submenu.outerHeight().toString().concat('px') : '');
              });
            })
            .on('mouseenter', '> .catalog-item', function() {
              $HEADER_MENU.trigger('setHeightCatalogSubmenu');
            })
            .on('mouseenter', '> .catalog-item > ul > li', function() {
              $HEADER_MENU.trigger('setHeightCatalogSubmenu', [$(this).children('ul')]);
            })

            .css('top', '')

            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');

          // Header menu catalog item layout
          $HEADER_MENU.find('> .catalog-item > ul > li > ul').isotope({
            layoutMode: 'masonry',
            percentPosition: true,
            transitionDuration: 0,
            containerStyle: null
          });
        }
      }
      else {
        $HEADER_MENU.css('top', HEADER_HEIGHT);

        if (!$HEADER.hasClass('is-mobile-events')) {
          $HEADER
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          $HEADER_MENU
            .off('setHeightCatalogSubmenu mouseenter')

            // Header menu catalog item layout
            .find('> .catalog-item > ul').css('min-height', '')
            .find('> li > ul').isotope('destroy');

          if ($HEADER_MENU.children('.info-item.is-hovered').length === 0)
            $HEADER_MENU.children('.catalog-item').addClass('is-hovered');

          $HEADER_MENU.find('.is-hovered > ul').css('display', 'block');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if (!($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest($HEADER_MENU).length)) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu > ul a, .header-menu > ul span, .header-menu > ul .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');


              if ($self.prop('tagName').toUpperCase() === 'A')
                location.href = $self.prop('href');
              else if (isWithDropdown) {
                if (isOnHover)
                  $parent.removeClass('is-hovered').children('ul').slideUp(300);
                else {
                  $parent.siblings().removeClass('is-hovered').children('ul').slideUp(300);
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              }
            });
        }
      }
    })

    // Init spinner
    // ------------
    .on('initSpinner basketListUpdated', function() {
      $.widget('ui.customSpinner', $.ui.spinner, {
        _enhance: function() {
          this.uiSpinner = this.element
            .attr( "autocomplete", "off" )
            .wrap( this._uiSpinnerHtml() )
            .parent().parent()

              // Add buttons
              .append( this._buttonHtml() );
        },
        _uiSpinnerHtml: function() {
          return "<div>";
        },
        _buttonHtml: function() {
          return ''.concat(
            '<div class="ui-spinner-button ui-spinner-up"><div><img src="/i/sp.gif" /></div></div>',
            '<div class="ui-spinner-button ui-spinner-down"><div><img src="/i/sp.gif" /></div></div>'
          );
        }
      });

      $('.js-spinner-input')
        .each(function() {
          var $spinner = $(this),
              min = $spinner.data('min') || 1,
              max = $spinner.data('max') || null,
              step = $spinner.data('step') || 1;

          $spinner
            .val(function(index, value) {
              if( value < min )
                return min;

              return value;
            })
            .customSpinner({
              culture: 'en',
              min: min,
              max: max,
              page: 0,
              step: step,

              change: function(e, ui) {
                e.preventDefault();

                var _min = $(e.target).data('min') || 1;

                if( $(e.target).val() < _min )
                  $(e.target).val(_min).trigger('change');
              },
              spin: function(e, ui) {
                e.preventDefault();

                $(e.target).val(ui.value).trigger('change');
              }
            });
        })
        .off('cut copy paste')
        .on('cut copy paste', function(e) {
          e.preventDefault();
        });
    })

    // Init selectric
    // --------------
    .on('initSelectric', function() {
      $('select').selectric({
        disableOnMobile: false,
        nativeOnMobile: true,
        openOnHover: true,
        hoverIntentTimeout: 0,
        inheritOriginalWidth: false
      });
    })

    // Show header search popup
    // ------------------------
    .on('click.showHeaderSearch', '.header-search-show-button > span', function(e) {
      e.preventDefault();

      if( $BODY.hasClass('search-is-opened') )
        $BODY.removeClass('search-is-opened');
      else {
        $BODY.addClass('search-is-opened');
        $('.search-form-container > form input').focus();
      }
    })

    // Hide header search popup
    // ------------------------
    .on('click.hideHeaderSearch', function(e) {
      if( !$(e.target).closest('.header-search-show-button > span').length && !$(e.target).closest('.search-form-container').length ) {
        $BODY.removeClass('search-is-opened');

        e.stopPropagation();
      }
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = ($lnk.data('scrollOffset') || 0);

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this).parent();

      if( !$lnk.hasClass('active') ) {
        var $parent = $lnk.parents('.js-tabs'),
            $destination = !!$parent.data('tabsDest') ? $($parent.data('tabsDest')) : $parent.next();

        $lnk.addClass('active').siblings().removeClass('active');
        $destination.children().addClass('dn').eq($lnk.index()).removeClass('dn');
      }
    })

    // Filters block show/hide
    // -----------------------
    .on('filtersBlockEvents', function(e) {
      if( IS_DESKTOP_WIDTH() ) {
        $('#product-filters').removeClass('is-opened').children('.filter-container').css('display', '');
        $('.filter-elements-container > div').removeClass('is-opened-mobile').children('.filter-dropdown-container').css('display', '');

        $('.product-left-content-placeholder > div').appendTo('.product-filters-wrapper');
      }
      else {
        $('.filter-elements-container > div:not(.is-opened-mobile) > .filter-dropdown-container').css('display', '');

        $('.product-filters-wrapper > div + div').appendTo('.product-left-content-placeholder');
      }
    })
    .on('click.filtersBlockCaption', '.product-filters-title', function(e) {
      e.preventDefault();

      if( !IS_DESKTOP_WIDTH() )
        $(this).parent().toggleClass('is-opened').children('.filter-container').slideToggle(300);
    })

    // Filter element dropdown show/hide
    // ---------------------------------
    .on('click.filterElementCaption', '.filter-elements-container .filter-dropdown-button', function(e) {
      e.preventDefault();

      var $parent = $(this).parent();

      if( IS_DESKTOP_WIDTH() )
        $parent.addClass('is-opening').children('.filter-dropdown-container').slideToggle(300, function() { $parent.toggleClass('is-opened').removeClass('is-opening'); });
      else
        $parent.toggleClass('is-opened-mobile').children('.filter-dropdown-container').slideToggle(300);
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      autoFocus: false,
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    }
  });

  $.fancyBoxConfirm = function(opts) {
    opts = $.extend(true, {
      title: 'Вы уверены?',
      message: '',
      buttons: {
        yes: {
          label: 'Да',
          cssClass: 'active'
        },
        no: {
          label: 'Нет',
          cssClass: ''
        }
      },
      callbacks: {
        yes: $.noop,
        no: $.noop
      }
    }, opts || {});

    var content = '<div class="form-popup-container fancybox-confirm">' +
      '<div class="form-block-container">' +
        '<div class="subsubheader">' + opts.title + '</div>' +
        opts.message +
        '<div class="form">' +
          '<div class="form-buttons">';

    $.each(opts.buttons, function(key, button) {
      if( button !== false )
        content = content + '<div class="form-button">' +
            '<button class="button-style ' + button.cssClass + '" data-fancybox-close data-value="' + key + '"><span>' + button.label + '</span></button>' +
          '</div>';
    });

    content = content + '</div>' +
          '</div>' +
        '</div>' +
      '</div>';

    $.fancybox.open({
      type: 'html',
      src: content,
      opts: {
        modal: true,
        baseTpl:
          '<div class="fancybox-container" role="dialog" tabindex="-1">' +
          '<div class="fancybox-bg"></div>' +
          '<div class="fancybox-inner">' +
          '<div class="fancybox-stage"></div>' +
          '</div>' +
          '</div>',
        afterClose: function(widget, currentSlide, e) {
          var button = e ? $(e.target).closest('button') || $(e.currentTarget).closest('button') : null,
              value = button ? $(button).data('value') : 'no';

          opts.callbacks[value](widget, currentSlide);
        }
      }
    });
  };
})();